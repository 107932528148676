import React from 'react';
import { Link } from "gatsby";
import SEO from "../components/Seo/Seo"
import SectionPageMargin from '../components/Layout/Section/SectionPageMargin'
import Heading1 from '../components/Type/Heading1'
import { StaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import Img from "gatsby-image"
import Logo from '../components/Navigation/assets/summon-logo-white.svg';
import Footer from '../components/FreePPSR/FooterPPSR'
import FreePPSR from '../components/FreePPSR/FreePPSR'
import "../components/Layout/Layout.css"

const ppsrPage = ({location}) => (
  <StaticQuery
    query={graphql`
      query  {

        pfaImage: file(relativePath: { eq: "ppsr/pfa-logo.jpg" }) {
          childImageSharp {
            fixed(width: 122, height: 73) {
            ...GatsbyImageSharpFixed
            }
          }
        }
        
      }
    `}
    render={data => {

      return (

        <>
          <SEO title="Free PPSR check for PFA Members"
            description="PFA have teamed up with Summon to offer free PPSR / VIN checks and PPSR Certificates for any Porsche."
            location={location}
          />

          <HeaderBlock>
            <div>
              <Img fixed={data.pfaImage.childImageSharp.fixed} alt="PFA" />
            </div>
            <div>
              <Link title="Summon Home" to="/"><Logo width="170px" alt="Summon" /></Link>
            </div>
          </HeaderBlock>

          <SectionPageMargin marginBottom="0px">
            <BackLink>&#9664; <a href="https://porscheforum.com.au">Back to PFA</a></BackLink>

            <Heading1 color={'#363636'}>
              Free PPSR check for PFA Members
            </Heading1>

            <TextBlock>
              <Heading2>
                PFA have teamed up with Summon to offer free PPSR / VIN checks and PPSR Certificates for any Porsche.
              </Heading2>
            </TextBlock>

          </SectionPageMargin>

          <FreePPSR
            source={'PFA'}
            carAlt={'Porsche'}
            backLink={{ text: 'Return to PFA', url: 'porscheforum.com.au' }}
          />

          <Footer />
        </>

      )

    }}
  />
)


export default ppsrPage

const HeaderBlock = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: black;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
`;

const TextBlock = styled.div`
  max-width: 800px;
`;

const BackLink = styled.div`
    color: #bbbbbb;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const Heading2 = styled.h2`
  color: #5f5f5f;
  font-weight: 400;
`;




